package ru.musintimur.instastat.database.InstastatMutliplatform

import com.squareup.sqldelight.Query
import com.squareup.sqldelight.TransacterImpl
import com.squareup.sqldelight.`internal`.copyOnWriteList
import com.squareup.sqldelight.db.SqlCursor
import com.squareup.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.MutableList
import kotlin.reflect.KClass
import ru.musintimur.instastat.database.Comments
import ru.musintimur.instastat.database.CompareDates
import ru.musintimur.instastat.database.GetLastMeasure
import ru.musintimur.instastat.database.GetProfileHistoryFollowers
import ru.musintimur.instastat.database.GetProfileHistoryFollowings
import ru.musintimur.instastat.database.GetProfileHistoryPosts
import ru.musintimur.instastat.database.InstastatDatabase
import ru.musintimur.instastat.database.InstastatDatabaseQueries
import ru.musintimur.instastat.database.Posts
import ru.musintimur.instastat.database.Profiles
import ru.musintimur.instastat.database.Users

internal val KClass<InstastatDatabase>.schema: SqlDriver.Schema
  get() = InstastatDatabaseImpl.Schema

internal fun KClass<InstastatDatabase>.newInstance(driver: SqlDriver): InstastatDatabase =
    InstastatDatabaseImpl(driver)

private class InstastatDatabaseImpl(
  driver: SqlDriver
) : TransacterImpl(driver), InstastatDatabase {
  public override val instastatDatabaseQueries: InstastatDatabaseQueriesImpl =
      InstastatDatabaseQueriesImpl(this, driver)

  public object Schema : SqlDriver.Schema {
    public override val version: Int
      get() = 1

    public override fun create(driver: SqlDriver): Unit {
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS usergroups (
          |	usergroup_id INTEGER PRIMARY KEY,
          |	usergroup_name TEXT NOT NULL
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS users (
          |	user_id INTEGER PRIMARY KEY,
          |	user_name TEXT NOT NULL,
          |	usergroup_id INTEGER NOT NULL DEFAULT 4,
          |	password_hash TEXT NOT NULL,
          |	CONSTRAINT users_fk FOREIGN KEY (usergroup_id) REFERENCES usergroups(usergroup_id) ON DELETE SET NULL ON UPDATE CASCADE
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS profiles (
          |	profile_id INTEGER PRIMARY KEY,
          |	profile_name TEXT NOT NULL,
          |	is_active INTEGER NOT NULL DEFAULT 1
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS profiles_history (
          |	profiles_history_id INTEGER PRIMARY KEY,
          |	profile_id INTEGER NOT NULL,
          |	metering_date TEXT NOT NULL,
          |	count_posts INTEGER NOT NULL DEFAULT 0,
          |	count_followers INTEGER NOT NULL DEFAULT 0,
          |	count_followings INTEGER NOT NULL DEFAULT 0,
          |	CONSTRAINT profiles_history_fk FOREIGN KEY (profile_id) REFERENCES profiles(profile_id) ON UPDATE RESTRICT ON DELETE RESTRICT
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS posts (
          |	post_id INTEGER PRIMARY KEY,
          |	post_url TEXT NOT NULL,
          |	post_date TEXT,
          |	post_text TEXT,
          |	comments_count INTEGER NOT NULL DEFAULT 0
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS comments (
          |    comment_id INTEGER PRIMARY KEY,
          |    post_id INTEGER NOT NULL,
          |    comment_author TEXT NOT NULL,
          |    comment_text TEXT NOT NULL,
          |    CONSTRAINT comments_fk FOREIGN KEY (post_id) REFERENCES posts(post_id) ON UPDATE RESTRICT ON DELETE RESTRICT
          |)
          """.trimMargin(), 0)
      driver.execute(null, "CREATE INDEX IF NOT EXISTS users_user_name_idx ON users(user_name)", 0)
      driver.execute(null,
          "CREATE INDEX IF NOT EXISTS users_usergroup_id_idx ON users(usergroup_id)", 0)
      driver.execute(null,
          "CREATE INDEX IF NOT EXISTS profiles_profile_name_id_idx ON profiles(profile_name)", 0)
      driver.execute(null,
          "CREATE INDEX IF NOT EXISTS profiles_history_profile_id_idx ON profiles_history(profile_id)",
          0)
      driver.execute(null,
          "CREATE UNIQUE INDEX IF NOT EXISTS posts_post_url_id_idx ON posts(post_url)", 0)
      driver.execute(null, "CREATE INDEX IF NOT EXISTS comments_post_id_idx ON comments(post_id)",
          0)
      driver.execute(null,
          "CREATE INDEX IF NOT EXISTS comments_author_idx ON comments(comment_author)", 0)
    }

    public override fun migrate(
      driver: SqlDriver,
      oldVersion: Int,
      newVersion: Int
    ): Unit {
    }
  }
}

private class InstastatDatabaseQueriesImpl(
  private val database: InstastatDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), InstastatDatabaseQueries {
  internal val getAllProfiles: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllActiveProfiles: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllActiveProfilesForUpdate: MutableList<Query<*>> = copyOnWriteList()

  internal val getProfileByName: MutableList<Query<*>> = copyOnWriteList()

  internal val getLastMeasure: MutableList<Query<*>> = copyOnWriteList()

  internal val compareDates: MutableList<Query<*>> = copyOnWriteList()

  internal val getProfileHistoryPosts: MutableList<Query<*>> = copyOnWriteList()

  internal val getProfileHistoryFollowers: MutableList<Query<*>> = copyOnWriteList()

  internal val getProfileHistoryFollowings: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllPosts: MutableList<Query<*>> = copyOnWriteList()

  internal val getPostByUrl: MutableList<Query<*>> = copyOnWriteList()

  internal val getPostById: MutableList<Query<*>> = copyOnWriteList()

  internal val getPostComments: MutableList<Query<*>> = copyOnWriteList()

  internal val findComment: MutableList<Query<*>> = copyOnWriteList()

  internal val calculatePostComments: MutableList<Query<*>> = copyOnWriteList()

  internal val getUserByName: MutableList<Query<*>> = copyOnWriteList()

  internal val checkCredentials: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> getAllProfiles(mapper: (
    profile_id: Int,
    profile_name: String,
    is_active: Boolean
  ) -> T): Query<T> = Query(96236747, getAllProfiles, driver, "InstastatDatabase.sq",
      "getAllProfiles", "SELECT * FROM profiles") { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getString(1)!!,
      cursor.getLong(2)!! == 1L
    )
  }

  public override fun getAllProfiles(): Query<Profiles> = getAllProfiles { profile_id, profile_name,
      is_active ->
    Profiles(
      profile_id,
      profile_name,
      is_active
    )
  }

  public override fun <T : Any> getAllActiveProfiles(mapper: (
    profile_id: Int,
    profile_name: String,
    is_active: Boolean
  ) -> T): Query<T> = Query(542508337, getAllActiveProfiles, driver, "InstastatDatabase.sq",
      "getAllActiveProfiles", """
  |SELECT * FROM profiles
  |    WHERE is_active = 1
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getString(1)!!,
      cursor.getLong(2)!! == 1L
    )
  }

  public override fun getAllActiveProfiles(): Query<Profiles> = getAllActiveProfiles { profile_id,
      profile_name, is_active ->
    Profiles(
      profile_id,
      profile_name,
      is_active
    )
  }

  public override fun <T : Any> getAllActiveProfilesForUpdate(mapper: (
    profile_id: Int,
    profile_name: String,
    is_active: Boolean
  ) -> T): Query<T> = Query(-856516415, getAllActiveProfilesForUpdate, driver,
      "InstastatDatabase.sq", "getAllActiveProfilesForUpdate", """
  |SELECT p.* FROM profiles p
  |WHERE is_active = 1
  |AND NOT EXISTS (
  |    SELECT ph.profiles_history_id
  |    FROM profiles_history ph
  |    WHERE ph.profile_id = p.profile_id
  |    AND date(metering_date) = date('today')
  |)
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getString(1)!!,
      cursor.getLong(2)!! == 1L
    )
  }

  public override fun getAllActiveProfilesForUpdate(): Query<Profiles> =
      getAllActiveProfilesForUpdate { profile_id, profile_name, is_active ->
    Profiles(
      profile_id,
      profile_name,
      is_active
    )
  }

  public override fun <T : Any> getProfileByName(profileName: String, mapper: (
    profile_id: Int,
    profile_name: String,
    is_active: Boolean
  ) -> T): Query<T> = GetProfileByNameQuery(profileName) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getString(1)!!,
      cursor.getLong(2)!! == 1L
    )
  }

  public override fun getProfileByName(profileName: String): Query<Profiles> =
      getProfileByName(profileName) { profile_id, profile_name, is_active ->
    Profiles(
      profile_id,
      profile_name,
      is_active
    )
  }

  public override fun <T : Any> getLastMeasure(mapper: (result: String?) -> T): Query<T> =
      Query(-547827192, getLastMeasure, driver, "InstastatDatabase.sq", "getLastMeasure", """
  |SELECT MAX(date(metering_date)) result
  |    FROM profiles_history
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public override fun getLastMeasure(): Query<GetLastMeasure> = getLastMeasure { result ->
    GetLastMeasure(
      result
    )
  }

  public override fun <T : Any> compareDates(date: String, mapper: (
    profile_name: String,
    cp1: Int,
    cp2: Int,
    cfl1: Int,
    cfl2: Int,
    cfg1: Int,
    cfg2: Int
  ) -> T): Query<T> = CompareDatesQuery(date) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!.toInt(),
      cursor.getLong(2)!!.toInt(),
      cursor.getLong(3)!!.toInt(),
      cursor.getLong(4)!!.toInt(),
      cursor.getLong(5)!!.toInt(),
      cursor.getLong(6)!!.toInt()
    )
  }

  public override fun compareDates(date: String): Query<CompareDates> = compareDates(date) {
      profile_name, cp1, cp2, cfl1, cfl2, cfg1, cfg2 ->
    CompareDates(
      profile_name,
      cp1,
      cp2,
      cfl1,
      cfl2,
      cfg1,
      cfg2
    )
  }

  public override fun <T : Any> getProfileHistoryPosts(
    profileId: Int,
    date1: String,
    date2: String,
    mapper: (metering_date: String, count_posts: Int) -> T
  ): Query<T> = GetProfileHistoryPostsQuery(profileId, date1, date2) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!.toInt()
    )
  }

  public override fun getProfileHistoryPosts(
    profileId: Int,
    date1: String,
    date2: String
  ): Query<GetProfileHistoryPosts> = getProfileHistoryPosts(profileId, date1, date2) {
      metering_date, count_posts ->
    GetProfileHistoryPosts(
      metering_date,
      count_posts
    )
  }

  public override fun <T : Any> getProfileHistoryFollowers(
    profileId: Int,
    date1: String,
    date2: String,
    mapper: (metering_date: String, count_followers: Int) -> T
  ): Query<T> = GetProfileHistoryFollowersQuery(profileId, date1, date2) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!.toInt()
    )
  }

  public override fun getProfileHistoryFollowers(
    profileId: Int,
    date1: String,
    date2: String
  ): Query<GetProfileHistoryFollowers> = getProfileHistoryFollowers(profileId, date1, date2) {
      metering_date, count_followers ->
    GetProfileHistoryFollowers(
      metering_date,
      count_followers
    )
  }

  public override fun <T : Any> getProfileHistoryFollowings(
    profileId: Int,
    date1: String,
    date2: String,
    mapper: (metering_date: String, count_followings: Int) -> T
  ): Query<T> = GetProfileHistoryFollowingsQuery(profileId, date1, date2) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!.toInt()
    )
  }

  public override fun getProfileHistoryFollowings(
    profileId: Int,
    date1: String,
    date2: String
  ): Query<GetProfileHistoryFollowings> = getProfileHistoryFollowings(profileId, date1, date2) {
      metering_date, count_followings ->
    GetProfileHistoryFollowings(
      metering_date,
      count_followings
    )
  }

  public override fun <T : Any> getAllPosts(mapper: (
    post_id: Int,
    post_url: String,
    post_date: String?,
    post_text: String?,
    comments_count: Int
  ) -> T): Query<T> = Query(-1559279982, getAllPosts, driver, "InstastatDatabase.sq", "getAllPosts",
      "SELECT * FROM posts ORDER BY post_date DESC") { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getString(1)!!,
      cursor.getString(2),
      cursor.getString(3),
      cursor.getLong(4)!!.toInt()
    )
  }

  public override fun getAllPosts(): Query<Posts> = getAllPosts { post_id, post_url, post_date,
      post_text, comments_count ->
    Posts(
      post_id,
      post_url,
      post_date,
      post_text,
      comments_count
    )
  }

  public override fun <T : Any> getPostByUrl(postUrl: String, mapper: (
    post_id: Int,
    post_url: String,
    post_date: String?,
    post_text: String?,
    comments_count: Int
  ) -> T): Query<T> = GetPostByUrlQuery(postUrl) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getString(1)!!,
      cursor.getString(2),
      cursor.getString(3),
      cursor.getLong(4)!!.toInt()
    )
  }

  public override fun getPostByUrl(postUrl: String): Query<Posts> = getPostByUrl(postUrl) { post_id,
      post_url, post_date, post_text, comments_count ->
    Posts(
      post_id,
      post_url,
      post_date,
      post_text,
      comments_count
    )
  }

  public override fun <T : Any> getPostById(postId: Int, mapper: (
    post_id: Int,
    post_url: String,
    post_date: String?,
    post_text: String?,
    comments_count: Int
  ) -> T): Query<T> = GetPostByIdQuery(postId) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getString(1)!!,
      cursor.getString(2),
      cursor.getString(3),
      cursor.getLong(4)!!.toInt()
    )
  }

  public override fun getPostById(postId: Int): Query<Posts> = getPostById(postId) { post_id,
      post_url, post_date, post_text, comments_count ->
    Posts(
      post_id,
      post_url,
      post_date,
      post_text,
      comments_count
    )
  }

  public override fun <T : Any> getPostComments(postId: Int, mapper: (
    comment_id: Int,
    post_id: Int,
    comment_author: String,
    comment_text: String
  ) -> T): Query<T> = GetPostCommentsQuery(postId) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getLong(1)!!.toInt(),
      cursor.getString(2)!!,
      cursor.getString(3)!!
    )
  }

  public override fun getPostComments(postId: Int): Query<Comments> = getPostComments(postId) {
      comment_id, post_id, comment_author, comment_text ->
    Comments(
      comment_id,
      post_id,
      comment_author,
      comment_text
    )
  }

  public override fun <T : Any> findComment(
    postId: Int,
    commentAuthor: String,
    commentText: String,
    mapper: (
      comment_id: Int,
      post_id: Int,
      comment_author: String,
      comment_text: String
    ) -> T
  ): Query<T> = FindCommentQuery(postId, commentAuthor, commentText) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getLong(1)!!.toInt(),
      cursor.getString(2)!!,
      cursor.getString(3)!!
    )
  }

  public override fun findComment(
    postId: Int,
    commentAuthor: String,
    commentText: String
  ): Query<Comments> = findComment(postId, commentAuthor, commentText) { comment_id, post_id,
      comment_author, comment_text ->
    Comments(
      comment_id,
      post_id,
      comment_author,
      comment_text
    )
  }

  public override fun calculatePostComments(postId: Int): Query<Long> =
      CalculatePostCommentsQuery(postId) { cursor ->
    cursor.getLong(0)!!
  }

  public override fun <T : Any> getUserByName(userName: String?, mapper: (
    user_id: Int,
    user_name: String,
    usergroup_id: Int,
    password_hash: String
  ) -> T): Query<T> = GetUserByNameQuery(userName) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getString(1)!!,
      cursor.getLong(2)!!.toInt(),
      cursor.getString(3)!!
    )
  }

  public override fun getUserByName(userName: String?): Query<Users> = getUserByName(userName) {
      user_id, user_name, usergroup_id, password_hash ->
    Users(
      user_id,
      user_name,
      usergroup_id,
      password_hash
    )
  }

  public override fun <T : Any> checkCredentials(
    userName: String?,
    passwordHash: String,
    mapper: (
      user_id: Int,
      user_name: String,
      usergroup_id: Int,
      password_hash: String
    ) -> T
  ): Query<T> = CheckCredentialsQuery(userName, passwordHash) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getString(1)!!,
      cursor.getLong(2)!!.toInt(),
      cursor.getString(3)!!
    )
  }

  public override fun checkCredentials(userName: String?, passwordHash: String): Query<Users> =
      checkCredentials(userName, passwordHash) { user_id, user_name, usergroup_id, password_hash ->
    Users(
      user_id,
      user_name,
      usergroup_id,
      password_hash
    )
  }

  public override fun insertNewStatistics(
    profile_id: Int,
    count_posts: Int,
    count_followers: Int,
    count_followings: Int
  ): Unit {
    driver.execute(-828890860, """
    |INSERT INTO profiles_history(profile_id, metering_date, count_posts, count_followers, count_followings)
    |    VALUES (?, now(), ?, ?, ?)
    """.trimMargin(), 4) {
      bindLong(1, profile_id.toLong())
      bindLong(2, count_posts.toLong())
      bindLong(3, count_followers.toLong())
      bindLong(4, count_followings.toLong())
    }
    notifyQueries(-828890860, {database.instastatDatabaseQueries.getProfileHistoryFollowings +
        database.instastatDatabaseQueries.getAllActiveProfilesForUpdate +
        database.instastatDatabaseQueries.getLastMeasure +
        database.instastatDatabaseQueries.compareDates +
        database.instastatDatabaseQueries.getProfileHistoryPosts +
        database.instastatDatabaseQueries.getProfileHistoryFollowers})
  }

  public override fun setProfileActivity(isActive: Boolean, profileId: Int): Unit {
    driver.execute(-988689044, """
    |UPDATE profiles SET is_active = ?
    |WHERE profile_id = ?
    """.trimMargin(), 2) {
      bindLong(1, if (isActive) 1L else 0L)
      bindLong(2, profileId.toLong())
    }
    notifyQueries(-988689044, {database.instastatDatabaseQueries.getProfileByName +
        database.instastatDatabaseQueries.getProfileHistoryFollowings +
        database.instastatDatabaseQueries.getAllActiveProfilesForUpdate +
        database.instastatDatabaseQueries.getLastMeasure +
        database.instastatDatabaseQueries.compareDates +
        database.instastatDatabaseQueries.getProfileHistoryPosts +
        database.instastatDatabaseQueries.getAllProfiles +
        database.instastatDatabaseQueries.getAllActiveProfiles +
        database.instastatDatabaseQueries.getProfileHistoryFollowers})
  }

  public override fun insertNewProfile(profileName: String): Unit {
    driver.execute(1155893080, """
    |INSERT INTO profiles (profile_name, is_active)
    |VALUES (?, 1)
    """.trimMargin(), 1) {
      bindString(1, profileName)
    }
    notifyQueries(1155893080, {database.instastatDatabaseQueries.getProfileByName +
        database.instastatDatabaseQueries.getAllActiveProfilesForUpdate +
        database.instastatDatabaseQueries.compareDates +
        database.instastatDatabaseQueries.getAllProfiles +
        database.instastatDatabaseQueries.getAllActiveProfiles})
  }

  public override fun insertNewPost(postUrl: String): Unit {
    driver.execute(1106973009, """INSERT INTO posts (post_url) VALUES (?)""", 1) {
      bindString(1, postUrl)
    }
    notifyQueries(1106973009, {database.instastatDatabaseQueries.getAllPosts +
        database.instastatDatabaseQueries.getPostByUrl +
        database.instastatDatabaseQueries.getPostById})
  }

  public override fun updatePostDateTime(postDate: String?, postUrl: String): Unit {
    driver.execute(373255706, """
    |UPDATE posts SET post_date = ?
    |WHERE post_url = ?
    """.trimMargin(), 2) {
      bindString(1, postDate)
      bindString(2, postUrl)
    }
    notifyQueries(373255706, {database.instastatDatabaseQueries.getAllPosts +
        database.instastatDatabaseQueries.findComment +
        database.instastatDatabaseQueries.calculatePostComments +
        database.instastatDatabaseQueries.getPostByUrl +
        database.instastatDatabaseQueries.getPostComments +
        database.instastatDatabaseQueries.getPostById})
  }

  public override fun updatePostText(postText: String?, postUrl: String): Unit {
    driver.execute(-1102865620, """
    |UPDATE posts SET post_text = ?
    |WHERE post_url = ?
    """.trimMargin(), 2) {
      bindString(1, postText)
      bindString(2, postUrl)
    }
    notifyQueries(-1102865620, {database.instastatDatabaseQueries.getAllPosts +
        database.instastatDatabaseQueries.findComment +
        database.instastatDatabaseQueries.calculatePostComments +
        database.instastatDatabaseQueries.getPostByUrl +
        database.instastatDatabaseQueries.getPostComments +
        database.instastatDatabaseQueries.getPostById})
  }

  public override fun updatePostCommentsCount(commentsCount: Int, postUrl: String): Unit {
    driver.execute(1440158812, """
    |UPDATE posts SET comments_count = ?
    |WHERE post_url = ?
    """.trimMargin(), 2) {
      bindLong(1, commentsCount.toLong())
      bindString(2, postUrl)
    }
    notifyQueries(1440158812, {database.instastatDatabaseQueries.getAllPosts +
        database.instastatDatabaseQueries.findComment +
        database.instastatDatabaseQueries.calculatePostComments +
        database.instastatDatabaseQueries.getPostByUrl +
        database.instastatDatabaseQueries.getPostComments +
        database.instastatDatabaseQueries.getPostById})
  }

  public override fun insertNewComment(
    postId: Int,
    commentAuthor: String,
    commentText: String
  ): Unit {
    driver.execute(-1879249906, """
    |INSERT INTO comments (post_id, comment_author, comment_text)
    |VALUES (?, ?, ?)
    """.trimMargin(), 3) {
      bindLong(1, postId.toLong())
      bindString(2, commentAuthor)
      bindString(3, commentText)
    }
    notifyQueries(-1879249906, {database.instastatDatabaseQueries.findComment +
        database.instastatDatabaseQueries.calculatePostComments +
        database.instastatDatabaseQueries.getPostComments})
  }

  private inner class GetProfileByNameQuery<out T : Any>(
    public val profileName: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getProfileByName, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-2005573973, """
    |SELECT p.* FROM profiles p
    |WHERE p.profile_name = ?
    """.trimMargin(), 1) {
      bindString(1, profileName)
    }

    public override fun toString(): String = "InstastatDatabase.sq:getProfileByName"
  }

  private inner class CompareDatesQuery<out T : Any>(
    public val date: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(compareDates, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-474459274, """
    |SELECT p.profile_name,
    |    ph1.count_posts cp1, ph2.count_posts cp2,
    |    ph1.count_followers cfl1, ph2.count_followers cfl2,
    |    ph1.count_followings cfg1, ph2.count_followings cfg2
    |FROM profiles p
    |    INNER JOIN profiles_history ph1 ON p.profile_id=ph1.profile_id
    |    INNER JOIN profiles_history ph2 ON p.profile_id=ph2.profile_id
    |WHERE date(timezone('Europe/Samara', ph1.metering_date)) = date(?) AND
    |    date(timezone('Europe/Samara', ph2.metering_date)) = (SELECT MAX(date(timezone('Europe/Samara', ph3.metering_date))) result
    |                                   FROM profiles_history ph3
    |                                   WHERE ph3.profile_id = ph2.profile_id AND
    |                                   (date(timezone('Europe/Samara', metering_date)) < date(?) OR
    |                                       (date(timezone('Europe/Samara', ph3.metering_date)) = date(?) AND NOT EXISTS
    |                                           (SELECT ph4.profiles_history_id FROM profiles_history ph4
    |                                           WHERE ph4.profile_id = ph2.profile_id
    |                                            AND date(timezone('Europe/Samara', ph4.metering_date)) < date(?))
    |                                       )
    |                                   ))
    |ORDER BY p.profile_name
    """.trimMargin(), 4) {
      bindString(1, date)
      bindString(2, date)
      bindString(3, date)
      bindString(4, date)
    }

    public override fun toString(): String = "InstastatDatabase.sq:compareDates"
  }

  private inner class GetProfileHistoryPostsQuery<out T : Any>(
    public val profileId: Int,
    public val date1: String,
    public val date2: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getProfileHistoryPosts, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-27654136, """
    |SELECT metering_date, count_posts FROM (
    |SELECT ph.metering_date, ph.count_posts
    |FROM profiles_history ph
    |WHERE ph.profile_id = ?
    |AND date(ph.metering_date) BETWEEN date(?) AND date(?)
    |ORDER BY ph.metering_date DESC) t
    |ORDER BY metering_date
    """.trimMargin(), 3) {
      bindLong(1, profileId.toLong())
      bindString(2, date1)
      bindString(3, date2)
    }

    public override fun toString(): String = "InstastatDatabase.sq:getProfileHistoryPosts"
  }

  private inner class GetProfileHistoryFollowersQuery<out T : Any>(
    public val profileId: Int,
    public val date1: String,
    public val date2: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getProfileHistoryFollowers, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1743211978, """
    |SELECT metering_date, count_followers FROM (
    |SELECT ph.metering_date metering_date, ph.count_followers
    |FROM profiles_history ph
    |WHERE ph.profile_id = ?
    |AND date(ph.metering_date) BETWEEN date(?) AND date(?)
    |ORDER BY ph.metering_date DESC) t
    |ORDER BY metering_date
    """.trimMargin(), 3) {
      bindLong(1, profileId.toLong())
      bindString(2, date1)
      bindString(3, date2)
    }

    public override fun toString(): String = "InstastatDatabase.sq:getProfileHistoryFollowers"
  }

  private inner class GetProfileHistoryFollowingsQuery<out T : Any>(
    public val profileId: Int,
    public val date1: String,
    public val date2: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getProfileHistoryFollowings, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1794888467, """
    |SELECT metering_date, count_followings FROM (
    |SELECT ph.metering_date metering_date, ph.count_followings
    |FROM profiles_history ph
    |WHERE ph.profile_id = ?
    |AND date(ph.metering_date) BETWEEN date(?) AND date(?)
    |ORDER BY ph.metering_date DESC) t
    |ORDER BY metering_date
    """.trimMargin(), 3) {
      bindLong(1, profileId.toLong())
      bindString(2, date1)
      bindString(3, date2)
    }

    public override fun toString(): String = "InstastatDatabase.sq:getProfileHistoryFollowings"
  }

  private inner class GetPostByUrlQuery<out T : Any>(
    public val postUrl: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPostByUrl, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1405209848, """
    |SELECT p.* FROM posts p
    |WHERE p.post_url = ?
    """.trimMargin(), 1) {
      bindString(1, postUrl)
    }

    public override fun toString(): String = "InstastatDatabase.sq:getPostByUrl"
  }

  private inner class GetPostByIdQuery<out T : Any>(
    public val postId: Int,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPostById, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1707896946, """
    |SELECT p.* FROM posts p
    |WHERE p.post_id = ?
    """.trimMargin(), 1) {
      bindLong(1, postId.toLong())
    }

    public override fun toString(): String = "InstastatDatabase.sq:getPostById"
  }

  private inner class GetPostCommentsQuery<out T : Any>(
    public val postId: Int,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPostComments, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1700438676,
        """SELECT * FROM comments WHERE post_id = ?""", 1) {
      bindLong(1, postId.toLong())
    }

    public override fun toString(): String = "InstastatDatabase.sq:getPostComments"
  }

  private inner class FindCommentQuery<out T : Any>(
    public val postId: Int,
    public val commentAuthor: String,
    public val commentText: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(findComment, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(659912912, """
    |SELECT * FROM comments
    |WHERE post_id = ?
    |AND comment_author = ?
    |AND comment_text = ?
    """.trimMargin(), 3) {
      bindLong(1, postId.toLong())
      bindString(2, commentAuthor)
      bindString(3, commentText)
    }

    public override fun toString(): String = "InstastatDatabase.sq:findComment"
  }

  private inner class CalculatePostCommentsQuery<out T : Any>(
    public val postId: Int,
    mapper: (SqlCursor) -> T
  ) : Query<T>(calculatePostComments, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1004095268, """
    |SELECT COUNT(*) FROM comments
    |WHERE post_id = ?
    """.trimMargin(), 1) {
      bindLong(1, postId.toLong())
    }

    public override fun toString(): String = "InstastatDatabase.sq:calculatePostComments"
  }

  private inner class GetUserByNameQuery<out T : Any>(
    public val userName: String?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getUserByName, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(584608525, """
    |SELECT * FROM users u
    |WHERE lower(trim(u.user_name)) = lower(trim(?))
    """.trimMargin(), 1) {
      bindString(1, userName)
    }

    public override fun toString(): String = "InstastatDatabase.sq:getUserByName"
  }

  private inner class CheckCredentialsQuery<out T : Any>(
    public val userName: String?,
    public val passwordHash: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(checkCredentials, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-394147670, """
    |SELECT * FROM users u
    |WHERE lower(trim(u.user_name)) = lower(trim(?))
    |AND u.password_hash = ?
    """.trimMargin(), 2) {
      bindString(1, userName)
      bindString(2, passwordHash)
    }

    public override fun toString(): String = "InstastatDatabase.sq:checkCredentials"
  }
}
